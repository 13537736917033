import { defineStore } from "pinia"
import { useWizardStore } from "@/stores/wizardStore.js"
import { fetchApiJson, postApiJson } from "@/services/bstApiService"

export default defineStore("newSimulationStore", {
  state: () => ({
    loadingIntermediate: false,
    errorIntermediate: false,
    loadingSimulation: false,
    loadingConfiguration: false,
    uid: null,
    dataframes: null,
  }),
  actions: {
    submitIntermediate(onSuccess, onFail) {
      const body = { ...useWizardStore().intermediateInput }
      if (this.uid) body.uid = this.uid
      this.loadingIntermediate = true
      postApiJson("/v2/simulations/intermediate-results/", body)
        .then((data) => {
          this.uid = data.uid
          this.dataframes = {
            ...JSON.parse(data.current_energetic_table),
            ...JSON.parse(data.current_financial_table),
            data_availability: {
              "Beschikbaarheid van de aansluiting voor levering":
                data.offtake_availability,
              "Beschikbaarheid van de aansluiting voor teruglevering":
                data.inflow_availability,
            },
          }
          this.errorIntermediate = false
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          this.errorIntermediate = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingIntermediate = false
        })
    },
    submitSimulation(uid, onSuccess, onFail) {
      const body = useWizardStore().simulationInput
      this.loadingSimulation = true
      let promise
      if (uid) {
        promise = postApiJson(`/v2/simulations/${uid}/`, body, {
          method: "PUT",
        })
      } else {
        promise = postApiJson("/v2/simulations/", body)
      }
      promise
        .then((data) => {
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingSimulation = false
        })
    },
    getConfiguration(uid, onSuccess, onNotFound, onFail) {
      this.loadingConfiguration = true
      fetchApiJson(`/v2/simulations/${uid}/configuration/`)
        .then((data) => {
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          if (error.code === 404 && onNotFound) {
            onNotFound()
            return
          }
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingConfiguration = false
        })
    },
  },
})
