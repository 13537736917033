import { defineStore } from "pinia"
import { postApiJson, fetchApiCatch } from "@/services/bstApiService"
import { excelToArray } from "@/services/excelService.js"
import { useDebounceFn } from "@vueuse/core"
import { format } from "date-fns"

export const operatorOptions = [
  { name: "Liander", value: "Liander" },
  { name: "Enexis", value: "Enexis" },
  { name: "Stedin", value: "Stedin" },
  { name: "Westland Infra", value: "Westland Infra" },
  { name: "Rendo", value: "Rendo" },
  { name: "Coteq", value: "Coteq" },
  { name: "Tennet", value: "Tennet" },
]

export const excelColumnOptions = [
  { label: "LDN", value: "LDN", tooltip: "Levering Door Netbeheerder" },
  { label: "BPM", value: "BPM", tooltip: "Bruto Productie Meter" },
  {
    label: "ODN en LDN",
    value: "ODN, LDN",
    tooltip: "Ontvangen Door Netbeheerder en Levering Door Netbeheerder",
  },
  {
    label: "ODN, LDN en BPM",
    value: "ODN, LDN, BPM",
    tooltip:
      "Ontvangen Door Netbeheerder, Levering Door Netbeheerder en Bruto Productie Meter",
  },
]

export const orientationOptions = [
  { name: "Oost-west", value: "east_west" },
  { name: "Zuid", value: "south" },
]

export const batteryTechnologyOptions = [
  { name: "Lithium", value: "li" },
  { name: "Vanadium", value: "va" },
]

export const strategyOptions = [
  {
    tooltip: "/bst/wizard/financial-strategy",
    label: "Financiële optimalisatie",
    value: "financial_optimization",
  },
  {
    tooltip: "/bst/wizard/self-sufficiency",
    label: "Zelfvoorzienendheid",
    value: "self_sufficiency",
  },
]

export const useWizardStore = defineStore("wizardStore", {
  /**
   * Variables marked as 'InputSelect item' are objects with structure
   * { name: string, value: string }, which should be accounted for when passing
   * data to the backend.
   */
  state: () => ({
    currentStep: 1,
    newName: null,
    simulation: {
      originalName: null,
      name: null,
      validName: null,
      simDescription: "",
      endDate: {
        month: 6,
        year: new Date().getFullYear(),
      },
    },
    grid: {
      importCapacity: null,
      exportCapacity: null,
      operator: null, // InputSelect item
    },
    excel: {
      demandTable: null,
      columns: null,
      errorList: null,
    },
    demand: {
      profile: null, // InputSelect item
      yearly: null,
    },
    solarParks: [], // 'orientation' within is an InputSelect item
    battery: {
      capacity: null,
      power: null,
      technology: null, // InputSelect item
      eiaPercentage: 0.1174,
      capex: null,
      capexIncludesInstallation: true,
      optimizationFeeFactor: 0,
    },
    strategy: null,
    useExcelData: false,
  }),
  getters: {
    /**
     * Prepare the data to be sent to the backend for the intermediate feedback.
     */
    intermediateInput: (state) => {
      const obj = {
        name: state.simulation.name,
        simulation_description: state.simulation.simDescription,
        end_date: format(
          new Date(
            state.simulation.endDate.year,
            state.simulation.endDate.month,
          ),
          "yyyy-MM-dd",
        ),
        grid: {
          import_capacity: state.grid.importCapacity,
          export_capacity: state.grid.exportCapacity,
          operator: state.grid.operator.value,
        },
      }
      if (state.useExcelData) {
        obj.demand_table = state.excel.demandTable
        obj.BPM = state.excel.columns.includes("BPM")
        obj.ODN = state.excel.columns.includes("ODN")
        obj.LDN = state.excel.columns.includes("LDN")
      }
      if (state.demand.profile) {
        obj.demand_profile = state.demand.profile.value
        obj.demand_yearly = state.demand.yearly
      }
      if (state.solarParks.length) {
        obj.solar_parks = state.solarParks.map((park) => ({
          orientation: park.orientation.value,
          AC: park.AC,
          DC: park.DC,
        }))
      }
      return obj
    },
    /**
     * Prepare the data to be sent to the backend for submitting a new
     * simulation.
     */
    simulationInput: (state) => ({
      ...state.intermediateInput,
      battery: {
        capacity: state.battery.capacity,
        power: state.battery.power,
        technology: state.battery.technology.value,
        costs: {
          capex: state.battery.capex || 0,
          capex_includes_installation: state.battery.capexIncludesInstallation,
          eia_percentage: state.battery.eiaPercentage,
          optimization_fee_factor: state.battery.optimizationFeeFactor,
        },
      },
      strategy: state.strategy,
    }),
  },
  actions: {
    /**
     * Manage entries in the 'solaPaks' state variable
     */
    addSolarPark() {
      this.solarParks.push({
        orientation: null,
        AC: "",
        DC: "",
      })
    },
    removeSolarPark(index) {
      this.solarParks.splice(index, 1)
    },
    /**
     * Besides validating the Excel file in the backend, this also stores the
     * validated data returned.
     */
    async validateExcelFile(file, onValid, onInvalid, onError) {
      this.excel.demandTable = null
      excelToArray(file)
        .then((excelArray) => {
          const body = {
            excel_data: excelArray,
            BPM: this.excel.columns.includes("BPM"),
            ODN: this.excel.columns.includes("ODN"),
            LDN: this.excel.columns.includes("LDN"),
          }
          return postApiJson("/v2/validate-uploaded-data/", body)
        })
        .then((data) => {
          this.excel.errorList = data.error_list
          if (data.valid) {
            this.excel.demandTable = data.demand_table
            if (onValid) onValid(data)
          } else {
            if (onInvalid) onInvalid(data)
          }
        })
        .catch((error) => {
          if (onError) onError(error)
        })
    },
    validateName: useDebounceFn(function (name) {
      if (name === this.simulation.originalName) {
        this.simulation.validName = true
      } else if (name?.length >= 3) {
        fetchApiCatch(`/v2/simulations/name-available/?name=${name}`)
          .then(() => (this.simulation.validName = true))
          .catch(() => (this.simulation.validName = false))
      }
    }, 300), // 300ms validation delay
    /**
     * Load a simulation configuration into the store's state.
     */
    loadConfiguration(config) {
      // Wizard state variables that are always present
      let dateString = config.end_date.split("T")[0]
      let date = new Date(dateString)
      const newState = {
        simulation: {
          originalName: config.name,
          name: config.name,
          simDescription: config.simulation_description,
          endDate: {
            month: date.getMonth(),
            year: format(date, "yyyy"),
          },
        },
        grid: {
          importCapacity: config.grid.import_capacity,
          exportCapacity: config.grid.export_capacity,
          operator: operatorOptions.find(
            (option) => option.value === config.grid.operator,
          ),
        },
        battery: {
          capacity: config.battery.capacity,
          power: config.battery.power,
          technology: batteryTechnologyOptions.find(
            (option) => option.value === config.battery.technology,
          ),
          eiaPercentage: config.battery.costs.eia_percentage,
          capex: config.battery.costs.capex,
          capexIncludesInstallation:
            config.battery.costs.capex_includes_installation,
        },
        strategy: config.strategy,
        useExcelData: config.BPM || config.ODN || config.LDN,
      }
      // Optional wizard state variables
      if (newState.useExcelData) {
        const flags = { ODN: config.ODN, LDN: config.LDN, BPM: config.BPM }
        newState.excel = {
          columns: Object.keys(flags)
            .filter((key) => flags[key])
            .join(", "),
          demandTable: config.demand_table,
          errorList: null,
        }
      }
      if (config.demand_profile) {
        newState.demand = {
          profile: {
            name: config.demand_profile,
            value: config.demand_profile,
          },
          yearly: config.demand_yearly,
        }
      }
      if (config.solar_parks) {
        newState.solarParks = config.solar_parks.map((park) => ({
          orientation: orientationOptions.find(
            (option) => option.value === park.orientation,
          ),
          AC: park.AC,
          DC: park.DC,
        }))
      }
      this.$patch(newState)
      this.validateName(newState.simulation.name)
    },
  },
})
