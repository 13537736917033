<template>
  <div class="flex justify-evenly xl:justify-center">
    <LoadingWrapper
      :loading="route.params.uid && newSimulationStore.loadingConfiguration"
    >
      <div class="hidden flex-1 xl:block" />
      <div class="flex flex-col items-center gap-4">
        <StepCreateSim
          ref="stepCreateSimRef"
          :is-active="wizardStore.currentStep === 1"
          @confirm-btn="() => nextButtonHandler(1)"
          @inactive-click="inactiveClickHandler(1)"
        />
        <StepConnection
          ref="stepConnectionRef"
          :is-active="wizardStore.currentStep === 2"
          @confirm-btn="nextButtonHandler(2)"
          @back-btn="backButtonHandler(2)"
          @inactive-click="inactiveClickHandler(2)"
        />
        <StepData
          ref="stepDataRef"
          :is-active="wizardStore.currentStep === 3"
          @confirm-btn="nextButtonHandler(3)"
          @back-btn="backButtonHandler(3)"
          @inactive-click="inactiveClickHandler(3)"
        />
        <StepConsumption
          ref="stepConsumptionRef"
          :is-active="wizardStore.currentStep === 4"
          :is-enabled="!wizardValidationStore.disabledSteps[4]"
          @confirm-btn="nextButtonHandler(4)"
          @back-btn="backButtonHandler(4)"
          @inactive-click="inactiveClickHandler(4)"
        />
        <StepGenerate
          ref="stepGenerateRef"
          :is-active="wizardStore.currentStep === 5"
          :is-enabled="!wizardValidationStore.disabledSteps[5]"
          @confirm-btn="nextButtonHandler(5)"
          @back-btn="backButtonHandler(5)"
          @inactive-click="inactiveClickHandler(5)"
        />
        <StepCalculation
          ref="stepCalculationRef"
          :is-active="wizardStore.currentStep === 6"
          :is-enabled="!wizardValidationStore.disabledSteps[6]"
          @confirm-btn="nextButtonHandler(6)"
          @back-btn="backButtonHandler(6)"
          @inactive-click="inactiveClickHandler(6)"
        />
        <StepBattery
          ref="stepBatteryRef"
          :is-active="wizardStore.currentStep === 7"
          :is-enabled="!!newSimulationStore.token"
          :disabled-next-button="!wizardValidationStore.allStepsValid"
          @confirm-btn="confirmButtonHandler"
          @back-btn="() => backButtonHandler(7)"
          @inactive-click="inactiveClickHandler(7)"
        />
      </div>
      <div class="flex justify-center xl:flex-1">
        <div class="relative flex flex-col">
          <VerticalStepper
            v-model="stepperIndex"
            :items="stepperItems"
            class="sticky top-0 py-6"
          />
        </div>
      </div>
    </LoadingWrapper>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useWizardStore } from "@/stores/wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore"
import { useNotificationStore } from "@/stores/useNotificationStore"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import {
  VerticalStepper,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import StepData from "@/components/wizard/StepData.vue"
import StepCreateSim from "@/components/wizard/StepCreateSim.vue"
import StepConnection from "@/components/wizard/StepConnection.vue"
import StepCalculation from "@/components/wizard/StepCalculation.vue"
import StepBattery from "@/components/wizard/StepBattery.vue"
import StepConsumption from "@/components/wizard/StepConsumption.vue"
import StepGenerate from "@/components/wizard/StepGenerate.vue"

const wizardValidationStore = useWizardValidationStore()
const notificationStore = useNotificationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const route = useRoute()
const router = useRouter()

const stepCreateSimRef = ref()
const stepConnectionRef = ref()
const stepDataRef = ref()
const stepConsumptionRef = ref()
const stepGenerateRef = ref()
const stepCalculationRef = ref()
const stepBatteryRef = ref()

const stepperItems = computed(() => [
  {
    label: "1. Simulatie aanmaken",
    done: stepCreateSimRef.value?.getValidationState === "valid",
    error: stepCreateSimRef.value?.getValidationState === "error",
    disabled: stepCreateSimRef.value?.getValidationState === "",
  },
  {
    label: "2. Elektriciteitsaansluiting",
    done: stepConnectionRef.value?.getValidationState === "valid",
    error: stepConnectionRef.value?.getValidationState === "error",
    disabled: stepConnectionRef.value?.getValidationState === "",
  },
  {
    label: "3. Eigen data",
    done: stepDataRef.value?.getValidationState === "valid",
    error: stepDataRef.value?.getValidationState === "error",
    disabled: stepDataRef.value?.getValidationState === "",
  },
  {
    label: "4. Verbruik",
    done: stepConsumptionRef.value?.getValidationState === "valid",
    error: stepConsumptionRef.value?.getValidationState === "error",
    disabled: stepConsumptionRef.value?.getValidationState === "",
  },
  {
    label: "5. Opwek",
    done: stepGenerateRef.value?.getValidationState === "valid",
    error: stepGenerateRef.value?.getValidationState === "error",
    disabled: stepGenerateRef.value?.getValidationState === "",
  },
  {
    label: "6. Berekening",
    done: stepCalculationRef.value?.getValidationState === "valid",
    error: stepCalculationRef.value?.getValidationState === "error",
    disabled: stepCalculationRef.value?.getValidationState === "",
  },
  {
    label: "7. Batterij",
    done: stepBatteryRef.value?.getValidationState === "valid",
    error: stepBatteryRef.value?.getValidationState === "error",
    disabled: stepBatteryRef.value?.getValidationState === "",
  },
])

/**
 * Converts the stepper index (0-indexed) to the current step (1-indexed).
 */
const stepperIndex = computed({
  get: () => wizardStore.currentStep - 1,
  set: (value) => (wizardStore.currentStep = value + 1),
})

function nextButtonHandler(index) {
  let nextStep = index + 1

  if (nextStep === 7 && !newSimulationStore.dataframes) {
    return
  }
  if (!wizardValidationStore.disabledSteps[nextStep]) {
    wizardStore.currentStep = nextStep
  } else {
    nextButtonHandler(index + 1)
  }
}

function backButtonHandler(index) {
  let previousStep = index - 1
  if (!wizardValidationStore.disabledSteps[previousStep]) {
    wizardStore.currentStep = previousStep
  } else {
    backButtonHandler(index - 1)
  }
}

function confirmButtonHandler() {
  newSimulationStore.submitSimulation(
    route.name === "wizard-edit" ? route.params.uid : null,
    () => {
      if (route.name === "edit-simulation") {
        notificationStore.pushToast(
          "De simulatie is bijgewerkt",
          "De berekeningen worden opnieuw uitgevoerd. Bekijk de status in het overzicht.",
        )
      } else {
        notificationStore.pushToast(
          "De simulatie is ingediend",
          "Bekijk de status in het overzicht.",
        )
      }
      wizardStore.$reset()
      newSimulationStore.$reset()
      router.push({ name: "simulations" })
    },
    (error) =>
      notificationStore.pushError(
        "Er ging iets mis bij het indienen van de simulatie",
        `Controleer of alle stappen correct zijn ingevuld en probeer het opnieuw (code: ${error.code}).`,
        "simulation-submit-error",
      ),
  )
}

function inactiveClickHandler(index) {
  return (wizardStore.currentStep = index)
}

/**
 * Process possible data from the route to load initial configuration.
 */
onMounted(() => {
  // Reset all stores and validation rules
  wizardStore.$reset()
  newSimulationStore.$reset()
  Object.values(wizardValidationStore.validationRules).forEach((rule) =>
    rule.$reset(),
  )
  // Scroll to step
  if (route.query.step) {
    const step = parseInt(route.query.step)
    if (!wizardValidationStore.disabledSteps[step]) {
      wizardStore.currentStep = step
    } else if (step === 4 || step === 5) {
      wizardStore.currentStep = 3
    }
  }
  // Load data from config if on edit route
  if (route.params.uid) {
    newSimulationStore.getConfiguration(
      route.params.uid,
      (config) => {
        wizardStore.loadConfiguration(config)
        Object.values(wizardValidationStore.validationRules).forEach((rule) =>
          rule.$validate(),
        )
      },
      () => {
        notificationStore.pushError(
          "Simulatie niet gevonden",
          `De simulatie met ID '${route.params.uid}' bestaat niet of is niet toegankelijk.`,
          "simulation-not-found",
        )
        router.push({ name: "simulations" })
      },
      (error) => {
        notificationStore.pushError(
          "Fout bij ophalen van simulatie",
          `Er is een fout opgetreden bij het ophalen van de simulatie (code: ${error.code}).`,
          "simulation-fetch-error",
        )
        router.push({ name: "simulations" })
      },
    )
  }
})
</script>
